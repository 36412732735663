<template>
  <div>
    <p>404</p>
  </div>
</template>

<script>
export default {
  data: () => ({
    //
  }),

  created() {
    this.$router.push({
      name: 'ContentTop'
    });
  }
};
</script>
